import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

//Components
import Layout from '../components/layout'
import SEO from '../components/seo'
import Phone from '../svg/phone.svg'
import Email from '../svg/email.svg'
import Twitter from '../svg/twitter.svg'
import Github from '../svg/github.svg'

export default () => {
  const queryData = useStaticQuery(graphql`
    {
      contact {
        coordonnees {
          name
          value
          link
        }
      }
    }
  `).contact.coordonnees

  const infoElements = queryData.filter(info => info.name !== 'linkedin').map(info => {
    const { name, value, link } = info
    let svgComponent

    switch (name) {
      case 'telephone':
        svgComponent = <Phone />
        break
      case 'email':
        svgComponent = <Email />
        break
      case 'twitter':
        svgComponent = <Twitter style={{ maxWidth: '24px' }} />
        break
      case 'github':
        svgComponent = <Github style={{ maxWidth: '24px' }} />
        break
      default:
        svgComponent = <div></div>
        break
    }

    return (
      <div className="coordonnees__bloc">
        {
          name === "email" ?
            <a href={`mailto:${value}`}>{svgComponent} {value}</a> :
            <a href={link} target="_blank">{svgComponent} {value}</a>
        }
      </div>
    )
  })

  return (
    <Layout className="page-contact">
      <SEO title="Contact" />
      <h1 className="page-title">
        <span className="page-title__hollow-bold">Me contacter</span>
        <br />
        <span className="page-title__red-line">
          Faisons <br />connaissance <span className="page-title__dot black">.</span>
        </span>
      </h1>
      <hr style={{ border: "solid #B8B8B8 1px", width: "100%", margin: "0 auto" }} />
      <h2 className="titre-coordonnees">Coordonnées</h2>
      <div>
        <div className="coordonnees">
          {infoElements}
        </div>
      </div>
    </Layout>
  )
}